import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-food-edit',
  templateUrl: './food-edit.component.html',
  styleUrls: ['./food-edit.component.scss'],
})
export class FoodEditComponent implements OnInit {
addForm:any;
id:any;
userProfile:any=localStorage.getItem('userProfile');

  constructor(
    public dialogRef: MatDialogRef<FoodEditComponent>,
    private toast:ToastComponent,
    private activatedRoute:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private CrudService:CrudService
  ) {
    this.CrudService.branchId = localStorage.getItem('branchId')


    //console.log(this.id,'thisid')
    this.addForm={
      productName:"",
      brand:"",
      weight:"",
      strength:"",
      lifeStage:"",
      nutritionalInformation:"",
      ingredients:"",
      type:"",
      proteinSource:"",
      flavour:"",
      HSN:"",
      currentQuantity:"",
      reorderLevel:"",
      reQuantity:"",
      batchNo:"",
      purchasePrice:"",
      retailPrice:"",
      maxRetailDiscount:"",
      BarCode:"",
      SGST:"",
      CGST:"",
      Inventorytype:"Food",
      location:"",
      clientId: this.userProfile?.clientId?._id,
      branchId: this.CrudService?.branchId
    }
    this.id = this.data.id;
    this.CrudService.getbyid(this.id,environment.api.food).then((res:any)=>{
      //console.log(res,'dataatatat')
      this.addForm=res;
    })
   }

  ngOnInit() {}
  closedialog(){
    this.dialogRef.close();
  }
  save(){

    //console.log('dataaaaaa')
    this.CrudService.edit(this.addForm,this.id,environment.api.food).then((res:any)=>{
      this.toast.presentToast("Edit Successfull")
    this.dialogRef.close()

    })
  }

}
