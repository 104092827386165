import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthloginService } from './services/login/authlogin.service';
import { AuthguardService } from './services/login/authguard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./notfound/notfound.module').then((m) => m.NotfoundPageModule),
  },

  //       {
  //         // canActivate: [AuthguardService],
  //         path: 'login',
  //         loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  //       },

  //  {
  //      path: 'login-otp',
  //    loadChildren: () => import('./pages/login-otp/login-otp.module').then( m => m.LoginOtpPageModule)
  //    },

  {
    path: 'main',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainPageModule),
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule
      ),
  },

  {
    // canActivate: [AuthguardService],
    path: 'branches',
    loadChildren: () =>
      import('./pages/branches/branches.module').then(
        (m) => m.BranchesPageModule
      ),
  },

  {
    // canActivate: [AuthguardService],
    path: 'login',
    loadChildren: () =>
      import('./pages/onboarding/login/login.module').then(
        (m) => m.LoginPageModule
      ),
  },

  {
    path: 'login-otp',
    loadChildren: () =>
      import('./pages/onboarding/login-otp/login-otp.module').then(
        (m) => m.LoginOtpPageModule
      ),
  },
  {
    path: 'pet-select',
    loadChildren: () =>
      import('./pages/onboarding/pet-select/pet-select.module').then(
        (m) => m.PetSelectPageModule
      ),
  },
  {
    path: 'success-screen',
    loadChildren: () =>
      import('./pages/onboarding/success-screen/success-screen.module').then(
        (m) => m.SuccessScreenPageModule
      ),
  },
  {
    path: 'user-details',
    loadChildren: () =>
      import('./pages/onboarding/user-details/user-details.module').then(
        (m) => m.UserDetailsPageModule
      ),
  },

  {
    path: ':slug',
    children: [
      {
        canActivate: [AuthloginService],

        path: '',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginPageModule),
      },
      {
        canActivate: [AuthloginService],

        path: 'business',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginPageModule),
      },
      {
        canActivate: [AuthloginService],
        path: 'login-otp/:code/:mobile',
        loadChildren: () =>
          import('./pages/login-otp/login-otp.module').then(
            (m) => m.LoginOtpPageModule
          ),
      },

      {
        canActivate: [AuthguardService],

        path: 'main',
        loadChildren: () =>
          import('./pages/main/main.module').then((m) => m.MainPageModule),
      },

      {
        canActivate: [AuthguardService],

        path: 'supplier',
        loadChildren: () =>
          import('./pages/supplier/supplier.module').then(
            (m) => m.SupplierPageModule
          ),
      },
      // {
      //   canActivate: [AuthguardService],

      //   path: 'service',
      //   loadChildren: () => import('./pages/service/service.module').then(m => m.ServicePageRoutingModule)
      // },
      {
        canActivate: [AuthguardService],

        path: 'branches',
        loadChildren: () =>
          import('./pages/branches/branches.module').then(
            (m) => m.BranchesPageModule
          ),
      },
      {
        canActivate: [AuthguardService],

        path: 'onboarding/user-details/:mobile',
        loadChildren: () =>
          import('./pages/onboarding/user-details/user-details.module').then(
            (m) => m.UserDetailsPageModule
          ),
      },
      {
        canActivate: [AuthguardService],

        path: 'onboarding/success-screen',
        loadChildren: () =>
          import(
            './pages/onboarding/success-screen/success-screen.module'
          ).then((m) => m.SuccessScreenPageModule),
      },
      {
        path: 'onboarding/login-otp/:code/:mobile',
        loadChildren: () =>
          import('./pages/onboarding/login-otp/login-otp.module').then(
            (m) => m.LoginOtpPageModule
          ),
      },
      {
        path: 'onboarding/login',
        loadChildren: () =>
          import('./pages/onboarding/login/login.module').then(
            (m) => m.LoginPageModule
          ),
      },
      {
        path: 'onboarding/pet-select/:id',
        loadChildren: () =>
          import('./pages/onboarding/pet-select/pet-select.module').then(
            (m) => m.PetSelectPageModule
          ),
      },
      {
        canActivate: [AuthguardService],

        path: 'manager-main',
        loadChildren: () =>
          import('./pages/manager-main/manager-main.module').then(
            (m) => m.ManagerMainPageModule
          ),
      },
      {
        canActivate: [AuthguardService],

        path: 'previous-log',
        loadChildren: () =>
          import('./pages/manager/previous-log/previous-log.module').then(
            (m) => m.PreviousLogPageModule
          ),
      },

      {
        path: '404',
        loadChildren: () =>
          import('./notfound/notfound.module').then(
            (m) => m.NotfoundPageModule
          ),
      },
      { path: '**', redirectTo: '404' },
    ],
  },

  // {
  //   path: ':slug',
  //   children: [
  //     {
  //       canActivate: [AuthloginService],

  //       path: '',
  //       loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  //     },
  //     {

  //       canActivate: [AuthloginService],

  //       path: 'business',
  //       loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  //     },
  //     {
  //       canActivate: [AuthloginService],
  //       path: 'login-otp/:code/:mobile',
  //       loadChildren: () => import('./pages/login-otp/login-otp.module').then(m => m.LoginOtpPageModule)
  //     },

  //     {
  //       canActivate: [AuthguardService],

  //       path: 'main',
  //       loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule)
  //     },

  //     {
  //       canActivate: [AuthguardService],

  //       path: 'supplier',
  //       loadChildren: () => import('./pages/supplier/supplier.module').then(m => m.SupplierPageModule)
  //     },
  //     {
  //       canActivate: [AuthguardService],

  //       path: 'service',
  //       loadChildren: () => import('./pages/service/service.module').then(m => m.ServicePageRoutingModule)
  //     }
  // //     {
  //       canActivate: [AuthguardService],

  //       path: 'branches',
  //       loadChildren: () => import('./pages/branches/branches.module').then(m => m.BranchesPageModule)
  //     },
  //     {
  //       canActivate: [AuthguardService],

  //       path: 'onboarding/user-details/:mobile',
  //       loadChildren: () => import('./pages/onboarding/user-details/user-details.module').then(m => m.UserDetailsPageModule)
  //     },
  //     {
  //       canActivate: [AuthguardService],

  //       path: 'onboarding/success-screen',
  //       loadChildren: () => import('./pages/onboarding/success-screen/success-screen.module').then(m => m.SuccessScreenPageModule)
  //     },
  //     {
  //       path: 'onboarding/login-otp/:code/:mobile',
  //       loadChildren: () => import('./pages/onboarding/login-otp/login-otp.module').then( m => m.LoginOtpPageModule)
  //     },
  //     {
  //       path: 'onboarding/login',
  //       loadChildren: () => import('./pages/onboarding/login/login.module').then( m => m.LoginPageModule)
  //     },
  //     {
  //       path: 'onboarding/pet-select/:id',
  //       loadChildren: () => import('./pages/onboarding/pet-select/pet-select.module').then( m => m.PetSelectPageModule)
  //     },
  //     {
  //       canActivate: [AuthguardService],

  //       path: 'manager-main',
  //       loadChildren: () => import('./pages/manager-main/manager-main.module').then(m => m.ManagerMainPageModule)
  //     },
  //     {
  //       canActivate: [AuthguardService],

  //       path: 'previous-log',
  //       loadChildren: () => import('./pages/manager/previous-log/previous-log.module').then(m => m.PreviousLogPageModule)
  //     },

  //     {
  //       path: '404',
  //       loadChildren: () => import('./notfound/notfound.module').then(m => m.NotfoundPageModule)
  //     },
  //     { path: '**', redirectTo: '404' }

  //   ]
  // },
  // {
  //   path: 'dialog',
  //   loadChildren: () => import('./pages/dialog/dialog.module').then( m => m.DialogPageModule)
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
