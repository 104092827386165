
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppSettings } from 'src/app/core/constant';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { LoadingserviceService } from '../loading/loadingservice.service';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { Observable } from 'rxjs';

// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
@Injectable({
  providedIn: 'root'
})
export class CrudService {
  public showSidebar:boolean = false;
branchId:any=localStorage.getItem('branchId')
flag:boolean;
  apiUrl : string = AppSettings.API_ENDPOINT;
	headers : any;
	accessToken : any;
  uploading : boolean = false;
  constructor(
  public http: HttpClient,
  private loadingService : LoadingserviceService,
  private toast : ToastComponent,
  // private fcm:FCM,
  private platform : Platform,
  private socket: Socket
  )
  {
    const config: SocketIoConfig = { url: AppSettings.API_ENDPOINT, options: {path:"/socket.io"} };



    this.headers = new Headers();
		this.headers.append('Access-Control-Allow-Origin' , '*');
		this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
		this.headers.append('Accept','application/json');
		this.headers.append('content-type','application/json');

    // this.headers = new HttpHeaders({
    //   Accept: 'application/json',
    //   'Content-Type': 'application/json',
    //   'JWT_Token': localStorage.getItem('token') || '',
    //   'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
    // }
    // );
		// this.headers.append('Access-Control-Allow-Origin' , '*');
		// this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
		// this.headers.append('Accept','application/json');
		// this.headers.append('content-type','application/json');
	  }

    addAppointment(data:any){
      console.log(data,'datafilter')
      this.socket.emit("schedule",data);
    }
    checkAppointment(data:any){
      console.log(data,'datafilterCA')
      this.socket.emit("checkschedule",data);
    }

    proceedAppointment(data:any){
      //console.log(data,'datafilter')
      this.socket.emit("proceedschedule",data);
    }


    add(data:any, model:any) {
      console.log(data,'data');
      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model;
      return new Promise((resolve, reject) => {

        this.http.post(addRatingURL, data, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            console.log("errr",err, err.error.message)
            if(err.error.message != ''){
              this.loadingService.hideLoading();
              this.toast.presentToastError(err.error.message);
              reject(err);
            }
            else{
              this.loadingService.hideLoading();
              this.toast.presentToastError(AppSettings.GENERIC_ERROR);
              reject(err);
            }
          });
      });

    }

    addForm(data:any, model:any) {
      //console.log(data,'data');
      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')
      let clinetId:any;
      this.loadingService.showLoading();
      clinetId = localStorage.getItem("userProfile")
      clinetId = JSON.parse(clinetId);
      console.log("clinetId",clinetId._id)
      let addRatingURL = this.apiUrl + 'businessUser/'+clinetId?._id;
      console.log("addRatingURL---------",addRatingURL)
      let payload = {
        makeDoctor: data
      }
      return new Promise((resolve, reject) => {

        this.http.patch(addRatingURL, payload, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }
    contactUs(data:any, model:any) {
      //console.log(data,'data');
      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      this.loadingService.showLoading();

      let addRatingURL = model;
      return new Promise((resolve, reject) => {

        this.http.post(addRatingURL, data, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }
      get(model:any,filterJSON:any) {
      let filter = encodeURI(JSON.stringify(filterJSON));
      //console.log(filter,'data76')

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      // this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model+ '?filter=' + filter;;
      return new Promise((resolve, reject) => {

        this.http.get(addRatingURL, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }
    getsingle(model:any) {

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      // this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model;;
      return new Promise((resolve, reject) => {

        this.http.get(addRatingURL, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }

    deleteid(id:any,model:any){

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model +'/' + id;
      return new Promise((resolve, reject) => {

        this.http.delete(addRatingURL, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });


    }
    getbyid(id:any,model:any){

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      // this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model + '/' +id;
      console.log("addRatingURL",addRatingURL);
      return new Promise((resolve, reject) => {

        this.http.get(addRatingURL, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            console.log("api response",res)
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            console.log("api response error",err)
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }
    getbyclinicInfo(id:any){

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      // this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + 'clients' + '/' +id;
      console.log("addRatingURL",addRatingURL);
      return new Promise((resolve, reject) => {

        this.http.get(addRatingURL, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            console.log("api response",res)
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            console.log("api response error",err)
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }
    updateManagerDetails(data: any, id:any){
      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      // this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + 'clients/' +id;
      return new Promise((resolve, reject) => {

        this.http.post(addRatingURL,data, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });
    }
    getbyPet(data:any,model:any){

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      // this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model;
      return new Promise((resolve, reject) => {

        this.http.post(addRatingURL,data, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });
    }

    edit(data:any,id:any, model:any) {

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );

      //console.log(this.headers,'headers')

      this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model + '/'+id;
      return new Promise((resolve, reject) => {

        this.http.patch(addRatingURL, data, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }
    webcamedit(data:any,id:any, model:any) {

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );

      //console.log(this.headers,'headers')

this.loadingService.showLoading()
      let addRatingURL = this.apiUrl + model + '/'+id;
      return new Promise((resolve, reject) => {

        this.http.patch(addRatingURL, data, { headers: headers })
          .subscribe(res => {
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }
    editpet(data:any,id:any,petId:any, model:any) {
      //console.log(petId,'petid')
      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model + '/'+id+'/'+petId;
      return new Promise((resolve, reject) => {

        this.http.patch(addRatingURL, data, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

    }
    addappoint(data:any,model:any){
      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      this.loadingService.showLoading();

      let addRatingURL = this.apiUrl + model;
      return new Promise((resolve, reject) => {

        this.http.post(addRatingURL, data, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });

}

countsales(model:any,filterJSON={}){

  const headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'JWT_Token': localStorage.getItem('token') || '',
    'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
  }
  );
  //console.log(this.headers,'headers')

  // this.loadingService.showLoading();
  let filter = encodeURI(JSON.stringify(filterJSON));
  let addRatingURL = this.apiUrl + model+'?filter=' + filter;
  return new Promise((resolve, reject) => {

    this.http.get(addRatingURL, { headers: headers })
      .subscribe(res => {
        this.loadingService.hideLoading();
        // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
        // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.toast.presentToastError(AppSettings.GENERIC_ERROR);
        reject(err);
      });
  });


}

count(model:any,filterJSON={}){

      const headers = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'JWT_Token': localStorage.getItem('token') || '',
        'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
      }
      );
      //console.log(this.headers,'headers')

      // this.loadingService.showLoading();
      let filter = encodeURI(JSON.stringify(filterJSON));
      let addRatingURL = this.apiUrl + model+ '/count'+'?filter=' + filter;
      return new Promise((resolve, reject) => {

        this.http.get(addRatingURL, { headers: headers })
          .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
            resolve(res);
          }, (err) => {
            this.loadingService.hideLoading();
            this.toast.presentToastError(AppSettings.GENERIC_ERROR);
            reject(err);
          });
      });


}


getClientDetail(id:any,model:any){



  // this.loadingService.showLoading();

  let addRatingURL = this.apiUrl + model + '/' +id + "?access_token=";
  return new Promise((resolve, reject) => {

    this.http.get(addRatingURL, { headers: this.headers })
      .subscribe(res => {
        this.loadingService.hideLoading();
        // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
        // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.toast.presentToastError(AppSettings.GENERIC_ERROR);
        reject(err);
      });
  });

}

put(id:any, data:any) {
  const headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'JWT_Token': localStorage.getItem('token') || '',
    'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
  }
  );
  //console.log(this.headers,'headers');
  this.loadingService.showLoading();
  let addRatingURL = this.apiUrl + 'appointment/changeContactedStatus/'+id;
  return new Promise((resolve, reject) => {
    this.http.patch(addRatingURL, data, { headers: headers })
      .subscribe(res => {
        this.loadingService.hideLoading();
        // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
        // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.toast.presentToastError(AppSettings.GENERIC_ERROR);
        reject(err);
      });

  });

}


getcontactedStatus(id:any){

  const headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'JWT_Token': localStorage.getItem('token') || '',
    'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
  }
  );
  //console.log(this.headers,'headers')

  // this.loadingService.showLoading();

  let addRatingURL = this.apiUrl + 'appointment/changeContactedStatus/' +id;
  return new Promise((resolve, reject) => {

    this.http.get(addRatingURL, { headers: headers })
      .subscribe(res => {
        this.loadingService.hideLoading();
        // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
        // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.toast.presentToastError(AppSettings.GENERIC_ERROR);
        reject(err);
      });
  });

}


sendPdf(data:any, model:any) {
  //console.log(data,'data');
  const headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'JWT_Token': localStorage.getItem('token') || '',
    'Authorization': localStorage.getItem('token') ? 'Bearer '+ (localStorage.getItem('token') || '')  : '',
  }
  );
  //console.log(this.headers,'headers')

  this.loadingService.showLoading();

  let addRatingURL = this.apiUrl + model;
  return new Promise((resolve, reject) => {

    this.http.post(addRatingURL, data, { headers: headers })
      .subscribe(res => {
        this.loadingService.hideLoading();
        // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
        // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
        resolve(res);
      }, (err) => {
        this.loadingService.hideLoading();
        this.toast.presentToastError(AppSettings.GENERIC_ERROR);
        reject(err);
      });
  });

}
}



