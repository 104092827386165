import {
  AfterViewInit,
  Component,
  ViewChild,
  OnInit,
  Inject,
  ElementRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogboxComponent } from 'src/app/components/dialogbox/dialogbox.component';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { ActivatedRoute } from '@angular/router';
import { CrudService } from 'src/app/services/crud/crud.service';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FileUpload } from 'src/app/shared/classes/file-upload';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { env } from 'process';
import { BillingComponent } from 'src/app/components/billing/billing.component';
import { EditPetDetailComponent } from 'src/app/components/edit-pet-detail/edit-pet-detail.component';
import { DOCUMENT } from '@angular/common';
import { ViewDetailedLogComponent } from '../view-detailed-log/view-detailed-log.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Location } from '@angular/common';
import { NavController } from '@ionic/angular';
import { Socket } from 'ngx-socket-io';
import { DialogRef } from '@angular/cdk/dialog';
import { HttpClient } from '@angular/common/http';
import { FileUploadService } from 'src/app/services/fileupload/file-upload.service';

@Component({
  selector: 'app-readydialog',
  templateUrl: './readydialog.component.html',
  styleUrls: ['./readydialog.component.scss'],
})
export class ReadydialogComponent implements OnInit {

  tokenNo: number = 0;
  userProfile: any = localStorage.getItem('userProfile');
data: any;  
userRole: any
  timerId: any;
  int: any;
  petdata: any
  opencases: boolean = false
  completed: boolean = false
  allcases: boolean = false;
  readypet: any;
  checkpet: any;

    price:any;
    method: any;
    status: any;
  selectedFiles: any;
  currentUpload: any;
  uploadResults: any;
  uploadDate: Date;

  constructor(
    private activatedRoute: ActivatedRoute,
    private CrudService: CrudService,
    private route: Router,
    public dialog: MatDialog,
    private toast: ToastComponent,
    private socket: Socket,
    private location: Location,
    private navCtrl: NavController,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA)
    public data1: any,
    public dialogRef: MatDialogRef<ReadydialogComponent>,
    private httpClient: HttpClient,
    private FileUploadService: FileUploadService,


  ) { 

    this.userProfile = JSON.parse(this.userProfile);
    this.CrudService.branchId = localStorage.getItem('branchId')

   }

  ngOnInit() {}

  openDialog(val: any): void {
    const dialogref = this.dialog.open(ReadydialogComponent, {
      data: val,
    });
    dialogref.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');

     
    });
  }

  goBack() {
    // this.route.navigate(['/consultation']);
    this.dialogRef.close()

  }
  
  
  readytosee(id: any) {
    console.log('idsddsd99',this.price)
    
    let data = {
      "status": "Approved",
      "paymentStatus": "On Going",
      "advance_consultation" : this.price
      
    }
    let customerData = {
      "lastvisit": new Date()

    }
    if(!this.method || !this.price){
      return
    }

    this.CrudService.edit(data, this.data1?.readytosee?._id, environment.api.appoinment).then((res: any) => {
      //console.log(res, 'res')
      if(res?.message == 'Updated Successfully'){
        let sourceData = {
          status: 'ok',
          updatedPrice: this.price
        }
        this.dialogRef.close(sourceData)
        // var startdate = new Date();
        // startdate?.setHours(0, 0, 0, 0);
        // var enddate = new Date();
        // enddate?.setHours(23, 59, 59, 999)
        // let filter = {
        //   "where": {
        //     "appoinmentTime": { '$gte': new Date(startdate), '$lte': new Date(enddate) },
        //     "check": "False",
        //     "clientId": this.userProfile?.clientId?._id,
        //     "branchId": this.CrudService?.branchId
        //   }
        // }

        // this.CrudService.get(environment.api.appoinment, filter).then((res: any) => {
       

        // })
      }
      this.tokenNo = res?.data?.token + 1
      //console.log(res?.data?.dogs, 'petready')

      this.CrudService.edit(customerData, res?.data?.dogs, 'dogs').then((res) => {
        var startdate = new Date();
        startdate?.setHours(0, 0, 0, 0);
        var enddate = new Date();
        enddate?.setHours(23, 59, 59, 999)
        let filter = {
          "where": {
            "appoinmentTime": { '$gte': new Date(startdate), '$lte': new Date(enddate) },
            "status": "Pending",
            "check": "True",
            "clientId": this.userProfile?.clientId?._id,
            "branchId": this.CrudService?.branchId
          }
        }
        this.CrudService.get(environment.api.appoinment, filter).then((res: any) => {
          if (res?.length > 0) {
            //console.log('call2')

            this.tokenNo = res[0]?.token
           // this.audio()

          } else {
            var startdate = new Date();
            startdate?.setHours(0, 0, 0, 0);
            var enddate = new Date();
            enddate?.setHours(23, 59, 59, 999)
            let filter = {
              "where": {
                "appoinmentTime": { '$gte': new Date(startdate), '$lte': new Date(enddate) },
                "check": "False",
                "clientId": this.userProfile?.clientId?._id,
                "branchId": this.CrudService?.branchId
              }
            }

            this.CrudService.get(environment.api.appoinment, filter).then((res: any) => {
              if (res?.length > 0) {
                //console.log('call3')

                this.tokenNo = res[0]?.token
                this.audio()
              } else {
                //console.log('call4')

                this.tokenNo = 0
               this.audio()
              }

            })


          }


        })

      })
      this.startCountdown(id, 0);

      this.getappPet();
      this.getappoint();
      this.checkget();

    })

  }

  getappoint() {
    var startdate = new Date();
    startdate?.setHours(0, 0, 0, 0);
    var enddate = new Date();
    enddate?.setHours(23, 59, 59, 999)
    let filter = {
      // "include": ["ownerId", "dogs"],
      "where": {
        "appoinmentTime": { '$gte': new Date(startdate), '$lte': new Date(enddate) },
        "status": "Pending",
        "check": "True",
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      }
    }

    this.CrudService.checkAppointment(filter)
    this.socket.on("checksendAppointment", (data: any) => {
      //console.log(data, 'data', this.petdata)

      this.petdata = [];
      data?.map((res: any) => {
        if (this.userProfile?.clientId?._id == res?.clientId) {
          this.petdata.push(res)
        }
      })

      //console.log(data, 'sendAppointment')
      // this.petdata = data
    });
    this.CrudService.get(environment.api.appoinment, filter).then((res: any) => {
      this.petdata = res;
      this.opencases = true
      this.completed = false;
      this.allcases = false;

      //console.log(res, 'data');

    })
  }

  getappPet() {
    var startdate = new Date();
    startdate?.setHours(0, 0, 0, 0);
    var enddate = new Date();
    enddate?.setHours(23, 59, 59, 999)
    let filter = {
      // "include": ["ownerId", "dogs"],
      "where": {
        "appoinmentTime": { '$gte': new Date(startdate), '$lte': new Date(enddate) },
        "status": "Approved",
        "check": "True",
        "paymentStatus": { $ne: "Completed" },
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId,
      }
    }
    this.CrudService.proceedAppointment(filter)
    this.socket.on("proceedsendAppointment", (data: any) => {
      //console.log(data, 'data', this.readypet)
      this.readypet = [];

      data?.map((res: any) => {
        if (this.userProfile?.clientId?._id == res?.clientId) {
          this.readypet.push(res)
        }
      })
      // this.readypet = [];
      //console.log(data, 'proceedsendAppointment')
      // this.readypet = data
    });
    this.CrudService.get(environment.api.appoinment, filter).then((res: any) => {
      this.readypet = res;
      this.opencases = true;
      this.completed = false;
      this.allcases = false;
    })

  }
  startCountdown(id: any, seconds: any) {
    let counter = seconds;
    this.timerId = id
    const interval = setInterval(() => {
      counter++;
      if (counter >= 10000) {
        clearInterval(interval);
        this.int = counter;
      }
      this.int = counter;

      //console.log('Ding!', this.int);
    }, 1000);
    this.int = counter;

    // if(this.int == 0){
    //   this.deleteappoint(this.timerId)
    // }
  }

  audio() {
    if (this.userRole == 'Front Desk' || this.userRole == 'Doctor') {
      const speak = (message: string) => {
        var msg = new SpeechSynthesisUtterance(message)

        var voices = window.speechSynthesis.getVoices()
        //console.log(msg, 'msg', voices)
        msg.voice = voices[0]
        window.speechSynthesis.speak(msg)
      }

      const form: any = document.getElementById('speech-form');
      const input: HTMLInputElement = document.getElementById('input') as HTMLInputElement;
      if (this.tokenNo != 0) {

        input.value = "Next token number is " + this.tokenNo
        speak(input.value);
        //console.log(input.value, 'value')
        input.value = ''
        this.tokenNo = 0
      } else {
        input.value = "No token available"
        speak(input.value);
        //console.log(input.value, 'value')
        input.value = ''
        this.tokenNo = 0


      }

    }
  }

  checkget() {
    var startdate = new Date();
    startdate?.setHours(0, 0, 0, 0);
    var enddate = new Date();
    enddate?.setHours(23, 59, 59, 999)
    let filter = {
      "where": {
        "appoinmentTime": { '$gte': new Date(startdate), '$lte': new Date(enddate) },
        "check": "False",
        "clientId": this.userProfile?.clientId?._id,
        "branchId": this.CrudService?.branchId
      }
    }

    let data = {
      startdate: new Date(startdate), enddate: new Date(enddate), check: "False", clientId: this.userProfile?.clientId?._id, branchId: this.CrudService?.branchId
    }
    this.CrudService.addAppointment(filter)
    this.socket.on("sendAppointment", (data: any) => {
      //console.log(data, 'data', this.checkpet)
      this.checkpet = [];
      data?.map((res: any) => {
        if (this.userProfile?.clientId?._id == res?.clientId) {
          this.checkpet.push(res)
        }
      })

      //console.log(data, 'sendAppointment', this.petdata)
    });
    this.CrudService.get(environment.api.appoinment, filter).then((res: any) => {
      this.checkpet = res;
      //console.log(this.checkpet, 'data');

    })

  }

  onFileChange(event: any): void {
    this.selectedFiles = event.target.files;
    this.uploadImages()
  }

  uploadImages(): void {
    if (this.selectedFiles) {
      const filesArray = Array.from(this.selectedFiles);

      this.FileUploadService.pushUploadmultiple(filesArray, '/appoinmentimages/' + this.data1?.readytosee?._id)
        .then((results) => {
          this.uploadResults = results;
          console.log('Uploaded URLs:', this.uploadResults);
          let currentDate = new Date();
          let formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
          this.uploadResults = this.uploadResults.map((urlObject: any) => {
            return {
              url: urlObject.url,
              date: formattedDate
            };
          });

          let obj = {
            "advance_consultation_image" : this.uploadResults,
          }
        

      
          this.CrudService.edit(obj, this.data1?.readytosee?._id, environment.api.appoinmentimages).then((res: any) => {
            //console.log(res, 'res')
            this.toast.presentToast("File Uploaded Successfully")

          })
          
        })
        .catch((error) => {
          console.error('Error uploading images:', error);
          // Handle error appropriately
        });
    }
  }

}
