import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};


@Component({
  selector: 'app-inventory-edit',
  templateUrl: './inventory-edit.component.html',
  styleUrls: ['./inventory-edit.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class InventoryEditComponent implements OnInit {
  addForm: any;
  userProfile: any = localStorage.getItem('userProfile');
  id: any
  tab: any
  liquids: boolean = false

  constructor(public dialogRef: MatDialogRef<InventoryEditComponent>,
    private toast: ToastComponent,
    private CrudService: CrudService,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {
    this.userProfile = JSON.parse(this.userProfile);
    this.CrudService.branchId = localStorage.getItem('branchId')

    this.addForm = {
      productName: "",
      Brand: "",
      DosageFom: "",
      Strength: "",
      Strength2: "",
      CurrentQuantity: "",
      ReOrderLevel: "",
      ReQuantity: "",
      SupplierInfo: "",
      ExpirationDate: "",
      BatchNo: "",
      StorageTemperature: "",
      IndicationForUse: "",
      ContraIndication: "",
      SideEffects: "",
      PurchasePrice: "",
      RetailPrice: "",
      reataildisc: "",
      BarCode: "",
      maxRetailDiscount: "",
      Location: "",
      HSN: "",
      SGST: "",
      CGST: "",
      Inventorytype: "Medicine",
      clientId: this.userProfile?.clientId?._id,
      branchId: this.CrudService?.branchId,
      // medicineId:"",
      notes: "",
      freeSamples: "",
      addQuantity: ""

    }
    this.id = this.data.id;
    this.CrudService.getbyid(this.id, environment.api.medicine).then((res: any) => {
      this.addForm = res;
      if (this.addForm.DosageFom == 'Tablets') {
        this.tab = 'mg/tab';

      }
      if (this.addForm.DosageFom == 'Capsules') {
        this.tab = 'mg/cap'

      }
      if (this.addForm.DosageFom == 'Liquids' || this.addForm.DosageFom == 'Suspensions' || this.addForm.DosageFom == 'Injections') {
        this.tab = 'mg/ml'

      }
      if (this.addForm.DosageFom == 'Liquids' || this.addForm.DosageFom == 'Suspensions')
        this.liquids = true;

    })
  }

  ngOnInit() { }
  closedialog() {
    this.dialogRef.close();
  }
  cancel(): void {
    // reset the form or close the modal
    // depending on your application's logic
  }
  save() {
    this.CrudService.edit(this.addForm, this.id, environment.api.medicine).then((res: any) => {
      this.toast.presentToast("Update Saved")
      this.dialogRef.close()

    })

    // this.addForm.reset()

  }
  liquid(event: any) {
    if (event == 'Tablets') {
      this.tab = 'mg/tab';

    }
    if (event == 'Capsules') {
      this.tab = 'mg/cap'

    }
    if (event == 'Liquids' || event == 'Suspensions' || event == 'Injections') {
      this.tab = 'mg/ml'

    }
      if (event == 'Liquids' || event == 'Suspensions') {
        this.liquids = true;

      } else {
        this.liquids = false;
      }
      //console.log(event, 'eent')

    }
  }

