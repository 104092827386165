<div class="inventory-edit">
  <div class="center-modal-header">

    <div>
      <h1>Add Food</h1>

    </div>
    <div style="padding-top: 6px;
  padding-right: 7px;">
      <button class="close-button" (click)="closedialog()"><img src="../assets/close-icon.svg"></button>
    </div>
  </div>
</div>
<div>
  <form class="example-form" style="max-width: 100%;" #Form="ngForm">
    <div style="display: flex;">

      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Product</mat-label>
        <input matInput type="text" placeholder="Name" required productName [(ngModel)]="addForm.productName"
          name="addForm.productName" />
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Brand</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="addForm.brand" name="addForm.brand">
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Weight(Kg)</mat-label>
        <input matInput placeholder="Kg" [(ngModel)]="addForm.weight" name="addForm.weight">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Life Stage</mat-label>
        <mat-select [(ngModel)]="addForm.lifeStage" name="addForm.lifeStage" placeholder="Select">
          <mat-option value="Neonatal">Neonatal</mat-option>
          <mat-option value="Transition">Transition</mat-option>
          <mat-option value="Infancy">Infancy</mat-option>
          <mat-option value="Juvenile">Juvenile</mat-option>
          <mat-option value="Adolescence">Adolescence</mat-option>
          <mat-option value="Adult">Adult</mat-option>
          <mat-option value="Mature adult">Mature adult</mat-option>
          <mat-option value="Senior">Senior</mat-option>
          <mat-option value="Geriatric">Geriatric</mat-option>
          <mat-option value="Transition">Transition</mat-option>
        </mat-select>

        <!-- <input matInput placeholder="Life Stage" [(ngModel)]="addForm.lifestage" name="addForm.lifestage"> -->
      </mat-form-field>
    </div>
    <div style="display: flex;">

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Nutrition
        </mat-label>
        <input matInput placeholder="Details" [(ngModel)]="addForm.nutritionalInformation"
          name="addForm.nutritionalInformation">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Type</mat-label>
        <mat-select [(ngModel)]="addForm.type" name="addForm.type" placeholder="Food Type" placeholder="Select">
          <mat-option value="Dry kibble">Dry kibble</mat-option>
          <mat-option value="Wet food (canned)">Wet food (canned)</mat-option>
          <mat-option value="Freeze-dried or dehydrated food">Freeze-dried or dehydrated food</mat-option>
          <mat-option value="Semi-moist food">Semi-moist food</mat-option>
          <mat-option value="Raw food diet">Raw food diet</mat-option>
          <mat-option value="Homemade food">Homemade food</mat-option>
          <mat-option value="Freeze-dried raw food">Freeze-dried raw food</mat-option>
          <mat-option value="Freeze-dried raw-coated kibble">Freeze-dried raw-coated kibble</mat-option>
          <mat-option value="Freeze-dried raw-coated freeze-dried kibble">Freeze-dried raw-coated freeze-dried
            kibble</mat-option>
        </mat-select>
        <!-- <input matInput placeholder="Type" [(ngModel)]="addForm.type" name="addForm.type"> -->
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Lot/Batch No.</mat-label>
        <input matInput placeholder="No." [(ngModel)]="addForm.batchNo" name="addForm.batchNo">
      </mat-form-field>

    </div>
    <!-- <div style="display: flex;"> -->

    <!-- <mat-form-field class="example-full-width">
        <mat-label> Ingredients</mat-label>
        <input matInput placeholder="Ingredients" [(ngModel)]="addForm.ingredients" name="addForm.ingredients">
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label> Protein source</mat-label>
        <input matInput placeholder="Protein source" [(ngModel)]="addForm.proteinSource" name="addForm.proteinSource">
      </mat-form-field> -->
    <!-- </div> -->
    <div style="display: flex;">

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Flavour</mat-label>
        <input matInput placeholder="Name" [(ngModel)]="addForm.flavour" name="addForm.flavour">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Current Quantity</mat-label>
        <input matInput placeholder="No's" [(ngModel)]="addForm.currentQuantity" name="addForm.currentQuantity">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Reorder Level</mat-label>
        <input matInput placeholder="No's" [(ngModel)]="addForm.reorderLevel" name="addForm.reorderLevel">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label> Reorder Quantity

        </mat-label>
        <input matInput placeholder="No's" [(ngModel)]="addForm.reQuantity" name="addForm.reQuantity">
      </mat-form-field>
    </div>
    <div style="display: flex;">



      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Purchase Price</mat-label>
        <input matInput placeholder="Rs" [(ngModel)]="addForm.purchasePrice" name="addForm.purchasePrice" required="">
      </mat-form-field>


      <mat-form-field class="example-full-width mando" [floatLabel]="'always'">
        <mat-label>Retail Price</mat-label>
        <input matInput placeholder="Rs" [(ngModel)]="addForm.retailPrice" name="addForm.retailPrice" required="">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Max Retail Discount(%)</mat-label>
        <input matInput placeholder="%" [(ngModel)]="addForm.maxRetailDiscount" name="addForm.maxRetailDiscount">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>HSN Code</mat-label>
        <input matInput placeholder="HSN Code" [(ngModel)]="addForm.HSN" name="addForm.HSN">
      </mat-form-field>

    </div>
    <div style="display: flex;">
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>SGST</mat-label>
        <input matInput placeholder="SGST" [(ngModel)]="addForm.SGST" name="addForm.SGST">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>CGST</mat-label>
        <input matInput placeholder="CGST" [(ngModel)]="addForm.CGST" name="addForm.CGST">
      </mat-form-field>
      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Barcode/SKU</mat-label>
        <input matInput placeholder="No." readonly [(ngModel)]="addForm.BarCode" name="addForm.BarCode">
      </mat-form-field>

      <mat-form-field class="example-full-width" [floatLabel]="'always'">
        <mat-label>Location
        </mat-label>
        <input matInput placeholder="Cabin etc" [(ngModel)]="addForm.location" name="addForm.location">
      </mat-form-field>
    </div>

  </form>
  <div class="modalFooter">
    <p>
      All set to add this food? Just give it one final check
    </p>
    <div>
      <button class="modalSecondaryBt" mat-raised-button type="submit" (click)="closedialog()">Cancel</button>
      <button class="modalPrimaryBt" mat-raised-button type="submit" [disabled]="Form.invalid" (click)="save()">Create
        Food</button>

    </div>
  </div>
</div>